.section-4 {
  width: 100%;
  height: 70vh;
  background-color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.section-4-heading {
  color: #fff;
  box-shadow: none;
  border-color: #fff;
  margin-bottom: 5rem;
}

.contact-form {
  width: 50rem;
  flex-direction: column;
}

#contact .invite {
  color: #fff;
  font-size: 2rem;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0;
  background-color: #eef;
  font-size: 1.5rem;
  border: 0.2rem solid #5353c6;
  color: #555;
}

.contact-form textarea {
  height: 10rem;
  max-height: 15rem;
  max-width: 100%;
}

.contact-form .contact-form-btn {
  background-color: #4242f3;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1rem;
  cursor: pointer;
  margin-top: 30px;
}

@media (max-width: 1200px) {
  .section-4 {
    height: 80vh;
  }
}

@media (max-width: 600px) {
  .contact-form {
    width: 40rem;
  }
}
