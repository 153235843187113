* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  font-family: "Jost", sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-heading {
  font-size: 5rem;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  margin-bottom: 10rem;
  border-bottom: 0.3rem solid #333;
  text-shadow: 0.2rem 0.2rem 0.5rem #555;
}

/* Responsive */
@media (max-width: 500px) {
  html {
    font-size: 55%;
  }
}

@media (max-width: 450px) {
  html {
    font-size: 45%;
  }
}
/* End of Responsive */
