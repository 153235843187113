.section-1 {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.3)
    ),
    url("../images/pexels-maria-orlova.jpg") center no-repeat;
  background-size: cover;
  flex-direction: column;
}

.section-1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.section-1-heading {
  font-size: 6rem;
  font-weight: 700;
  color: #fff;
  padding: 0 3rem 1rem;
  margin-bottom: 5rem;
  letter-spacing: 1rem;
  border-bottom: 0.3rem solid #8585ad;
  text-shadow: 0.1rem 0.1rem 0.1rem #555;
  text-align: center;
  z-index: 10;
}

.section-1-descr {
  font-size: 2rem;
}

.person-name {
  font-size: 6rem;
  color: #e41c6f;
  text-shadow: 0.1rem 0.1rem 0.1rem #555;
  letter-spacing: 0.3rem;
}

.section-1-btn {
  font-size: 1.7rem;
  font-weight: 700;
  color: #333;
  background-color: #fff;
  border: 0.3rem solid #5353c6;
  padding: 0.8rem 1rem;
  letter-spacing: 0.3rem;
  z-index: 10;
}

@media (max-width: 1200px) {
  .section-1-heading {
    font-size: 4rem;
  }
  .person-name {
    font-size: 4rem;
  }
}

@media (max-width: 800px) {
  .section-1-heading {
    font-size: 2rem;
  }
  .person-name {
    font-size: 2rem;
  }
}

@media (max-width: 450px) {
  .section-1-heading {
    font-size: 3rem;
  }

  .person-name {
    font-size: 3rem;
  }
}
