.navbar {
  width: 100%;
  height: 4rem;
  background-color: #5353c6;
  box-shadow: 0.2rem 0.2rem 0.5rem #555;
  position: absolute;
  z-index: 300;
}

.sticky {
  position: fixed;
  top: 0;
}

.navbar-link {
  font-size: 1.7rem;
  font-weight: 600;
  color: #333;
  margin: 0 3rem;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  position: relative;
  transition: color 0.2s;
}

.change.navbar-link {
  color: #fff;
}

.navbar-link:hover {
  color: #fff;
}

.navbar-link::before {
  content: "";
  width: 0;
  height: 0.2rem;
  background-color: #fff;
  position: absolute;
  right: -15%;
  bottom: -0.3rem;
  transition: width 0.2s;
}

.change.navbar-link::before {
  width: 130%;
}

.navbar-link:hover::before {
  width: 130%;
}

@media (max-width: 600px) {
  .navbar-link {
    margin: 0 2rem;
    font-size: 1.5rem;
  }
}

@media (max-width: 450px) {
  .navbar-link {
    margin: 0 1.5rem;
  }
}
