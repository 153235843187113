.section-2 {
  width: 100%;
  height: 100%;
  padding: 15rem 0 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.services {
  width: 100vw;
  height: 50vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 10rem 0rem 10rem;
}

.service {
  max-width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0.8rem 1rem;
}

.service i {
  font-size: 10rem;
  color: #888;
  margin-bottom: 2rem;
  text-shadow: 0.1rem 0.1rem 0.5rem #555;
}

.service h2 {
  font-size: 1.5rem;
  color: #fff;
  background-color: #777;
  width: 25rem;
  letter-spacing: 0.3rem;
  transform: skew(-10deg);
  box-shadow: 0.2rem 0.2rem 0.5rem #555;
  text-align: center;
}

.service-description {
  font-size: 1.5rem;
  margin-top: 2rem;
  padding: 0.8rem 1rem;
  letter-spacing: 0.2rem;
  line-height: 2.5rem;
  color: #333;
}

.progress-bars-wrapper {
  margin-bottom: 10rem;
}

.progress-bar {
  width: 60rem;
  height: 3rem;
  background-color: #ccc;
  margin: 1.5rem 0;
  border-radius: 0.3rem;
  box-shadow: 0.2rem 0.2rem 1rem #555;
  position: relative;
}

.progress-text {
  position: absolute;
  left: 1rem;
  font-size: 1.5rem;
  color: #fff;
}

.progress-text span {
  margin: 0.5rem;
  font-size: 1.8rem;
  font-weight: 700;
}

.progress-percentage {
  width: 0;
  height: 100%;
  background-color: #5353c6;
  border-radius: 0.3rem;
  transition: width 0.5s 0.5s ease-in-out;
}
.section-2-details {
  width: 100vw;
  background-color: #f2f2f2;
}

.section-2-container {
  max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 30px auto;
  padding: 0 3rem;
}

.section-2-details .column-1 {
  max-width: 45vw;
}

.column-1-heading {
  font-size: 3rem;
  margin-bottom: 2rem;
  text-shadow: 0.1rem 0.1rem 0rem #555;
}

.column-1-description {
  font-size: 1.5rem;
  padding: 0.8rem 1rem;
  letter-spacing: 0.1rem;
  line-height: 2.5rem;
  max-width: 100vw;
  width: 80%;
  color: #333;
  margin-bottom: 1.2rem;
}

.credentials ul {
  max-width: 80%;
  padding-left: 2rem;
}
.credentials ul li {
  font-size: 1.5rem;
  color: #333;
  list-style: square;
}

.credentials ul li span {
  font-weight: 700;
  color: #333;
}

@media (max-width: 1200px) {
  .column-1-description {
    width: 100%;
  }

  .services {
    height: auto;
    flex-wrap: wrap;
  }

  .service {
    margin: 3rem 2rem;
  }

  .section-2-details {
    padding: 0 5rem;
  }

  .section-2-details .column-1 {
    max-width: 100%;
  }

  .progress-bars-wrapper {
    width: 100vw;
    margin-top: 5rem;
  }

  .progress-bar {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .section-2 {
    padding: 10rem 0 0;
  }

  .section-2-heading {
    margin-bottom: 10rem;
  }
  .progress-bar {
    width: 45rem;
  }
  .section-2-details {
    width: 100vw;
  }
}

@media (max-width: 450px) {
  .section-2-heading {
    text-align: center;
  }

  .progress-bar {
    width: 40rem;
  }
}
