* {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
button {
  overflow: visible;
  width: inherit;
  margin: inherit;
  padding: inherit;
  border: none;
  background: inherit;
  font: inherit;
  line-height: normal;
  color: inherit;
  text-align: inherit;
  -webkit-appearance: none;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
  padding: 0;
}

.dd-wrapper {
  position: relative;
  width: 222px;
  font-size: 1.6rem;
  user-select: none;
  margin-bottom: 20px;
}

.dd-wrapper .dd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 3px;
  background-color: #5353c6;
  line-height: 38px;
  cursor: default;
  cursor: pointer;
  color: #fff;
  width: 100%;
}

.dd-wrapper .dd-header span {
  margin-right: 20px;
}

.dd-wrapper .dd-header-title {
  margin: 2px 20px;
  margin-right: 30px;
  font-weight: 300;
}

.dd-wrapper .fa-angle-down,
.dd-wrapper .fa-angle-up {
  margin-right: 20px;
  color: #fff;
}

.dd-wrapper .dd-list {
  position: absolute;
  z-index: 10;
  width: 100%;
  border: 1px solid rgb(223, 223, 223);
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
  background-color: white;
  font-weight: 700;
  text-align: left;
  -webkit-overflow-scrolling: touch;
  margin-top: -30px;
}

.dd-wrapper .dd-list .dd-sroll-list {
  overflow-y: scroll;
  max-height: 215px;
  padding: 15px 0;
}

.dd-list-item {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  padding: 8px 10px;
  font-size: 1.5rem;
  line-height: 1.6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
  cursor: pointer;
}

.dd-list-item .no-result {
  font-weight: normal;
  cursor: default;
}

.dd-list-item .no-result:hover {
  background-color: transparent;
  color: black;
}

.dd-list-item.selected {
  background-color: #5353c6;
  color: white;
}

.dd-list-item:hover {
  background-color: #5353c6;
  color: white;
}

.dd-list-search-bar {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-top: none;
  border-right: none;
  border-left: none;
  font-size: inherit;
}

.dd-list-search-bar::placeholder {
  color: rgb(200, 200, 200);
}

.dd-list-search-bar .searchable {
  overflow-y: hidden;
  padding: 0;
}

.searchable .dd-sroll-list {
  max-height: calc(215px - 40px);
  padding: 10px 0;
}
