.alert {
  width: 100%;
  color: white;
  font-size: 2rem;
  margin-bottom: 15px;
  text-align: center;
  padding: 20px;
  transition: width 10s 5s ease-in;
}

.alert-success {
  background-color: #4caf50;
}

.alert-danger {
  background-color: #f44336;
}
