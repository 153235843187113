#chevron-left {
  position: relative;
  text-align: center;
  padding: 0px;
  margin-bottom: 0px;
  height: 5px;
  width: 15px;
  transform: rotate(90deg);
}
#chevron-left:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 51%;
  background: #303030;
  transform: skew(0deg, 40deg);
}
#chevron-left:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  background: #303030;
  transform: skew(0deg, -40deg);
}

#chevron-left.disable::before {
  background: #a0a0a0;
}

#chevron-left.disable::after {
  background: #a0a0a0;
}

#chevron-right {
  position: relative;
  text-align: center;
  padding: 0px;
  margin-bottom: 0px;
  height: 5px;
  width: 15px;
  transform: rotate(270deg);
}
#chevron-right:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 51%;
  background: #303030;
  transform: skew(0deg, 40deg);
}
#chevron-right:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  background: #303030;
  transform: skew(0deg, -40deg);
}

#chevron-right.disable::before {
  background: #a0a0a0;
}

#chevron-right.disable::after {
  background: #a0a0a0;
}
