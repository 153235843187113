.section-5 {
  width: 100%;
  height: 30vh;
  background-color: #222;
  flex-direction: column;
}

.social-media {
  margin-bottom: 5rem;
}

.social-media-link {
  margin: 0 2rem;
  padding: 0.7rem;
  background-color: #5353c6;
  border-radius: 0.5rem;
  display: inline-block;
}

.social-media-link i {
  font-size: 3rem;
  color: #fff;
}

.copyright {
  color: #aaa;
  font-size: 2rem;
  letter-spacing: 0.2rem;
  font-weight: 300;
  text-align: center;
}

@media (max-width: 600px) {
  .copyright {
    width: 70%;
  }
}
