.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
}

.pagination li {
  font-size: 14px;
  font-weight: normal;
  float: left;
  padding: 8px 16px;
  cursor: pointer;
  transition: cubic-bezier(0.23, 1, 0.32, 1);
}

.pagination li a {
  text-decoration: none;
  color: #0d0f10;
}

.pagination li.active > a {
  color: #5353c6;
  font-weight: bold;
}

.pagination-numbers {
  color: #0d0d0d;
}
.pagination-arrows {
  color: #0d0d0d;
}

.pagination li.disable,
.disable a,
.disable {
  cursor: default !important;
  color: gray;
}

@media (max-width: 481px) {
  .pagination li {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .pagination li.active,
  .pagination li:first-of-type,
  .pagination li:last-of-type {
    position: initial;
    top: initial;
    left: initial;
  }
}
