body {
  background-color: #eef;
}

.section-3 {
  height: 100%;
  width: 100%;
  padding: 10rem 0;
  background-color: #eef;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.all-projects-heading {
  padding-top: 30px;
}

.all-projects-section {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.all-projects {
  font-size: 1.8rem;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 1.2rem;
}

.pagination-wrap {
  width: 100vw;
  display: grid;
  flex-direction: column;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
}

.container-outer {
  width: 100vw;
}

.project {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  background-color: #fff;
  box-shadow: 0.3rem 0.3rem 0.5rem #777;
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.5s;
  width: 400px;
  min-width: 400px;
  max-width: 100%;
  height: 300px;
}

.project:hover {
  box-shadow: 1rem 1rem 1rem #777;
}

.project-text {
  text-align: center;
  letter-spacing: 0.1rem;
  position: absolute;
  top: -10rem;
  z-index: 10;
  transition: top 0.3s;
}

.project:hover .project-text {
  top: 5rem;
  transition: top 0.3s 0.3s;
}

.project-name {
  font-size: 3rem;
  font-weight: 700;
  color: #333;
  text-transform: capitalize;
}

.project-technologies {
  font-size: 1.8rem;
  color: #5353c6;
}

.project-img {
  width: 100%;
  object-fit: scale-down;
  transition: opacity 0.3s;
  padding: 1rem;
}

.project:hover .project-img {
  opacity: 0.2;
}

.project-link {
  font-size: 2rem;
  font-weight: 500;
  color: #333;
  letter-spacing: 0.1rem;
  border: 0.3rem solid #5353c6;
  padding: 0 1rem;
  background-color: #fff;
  position: absolute;
  bottom: -5rem;
  transition: bottom 0.3s;
}

.project:hover .project-link {
  bottom: 5rem;
  transition: bottom 0.3s 0.3s;
}

@media (max-width: 600px) {
  .project-img {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .pagination-wrap {
    grid-template-columns: 1fr 1fr;
    width: 100vw;
  }
}

@media (max-width: 830px) {
  .pagination-wrap {
    grid-template-columns: 1fr;
  }
}
