.projects-wrapper {
  max-width: 900px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

a.all-projs {
  color: #5353c6;
  font-weight: 800;
  text-decoration: underline;
}
@media (max-width: 1200px) {
  .projects-wrapper {
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }
  .project {
    width: 90%;
  }
}

@media (max-width: 830px) {
  .projects-wrapper {
    grid-template-columns: 1fr;
  }
}
